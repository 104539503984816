// Note: Updating the available languages? Make sure to also update the
// locales array in shared/utils/date.ts to enable translation for timestamps.
export const languageOptions = [
  {
    label: "Russian (RU)",
    value: "ru_RU",
  },
  {
    label: "English (US)",
    value: "en_US",
  },
  {
    label: "Українська (Ukrainian)",
    value: "uk_UA",
  },
];

export const languages = languageOptions.map((i) => i.value);
